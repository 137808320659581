import React from "react";
import Footer from "../layout/Footer";
import Header from "../layout/Header";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import * as Yup from "yup";
import emailjs from "emailjs-com";

const ContactUs = () => {
  const { t } = useTranslation();

  let toastId = null;
  const displayToast = (message) => {
    if (!toast.isActive(toastId)) {
      toastId = toast(message, {
        closeOnClick: false,
        progressClassName: "toastProgress",
        pauseOnHover: true,
        toastId: "my_toast",
        closeButton: false,
        position: "top-right",
        autoClose: 6000,
        hideProgressBar: true,
      });
    }
  };

  return (
    <div class="home page-template-default page page-id-3699 wpb-js-composer js-comp-ver-5.2.1 vc_responsive">
      <Header />

      <div class="content-block">
        <div
          class="container-bg with-bg container-fluid"
          data-style="background-image: url(assets/img/contact_us_pipacs_main.webp);"
        >
          <div class="container-bg-overlay">
            <div class="container">
              <div class="row">
                <div class="col-md-12">
                  <div class="page-item-title">
                    <h1 class="text-center texttransform-none">
                      {/* Kapcsolat */}
                      {t("contactUs")}
                    </h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="page-container container">
          <div class="row">
            <div class="col-md-12 entry-content">
              <article>
                <div class="vc_row wpb_row vc_row-fluid">
                  <div class="wpb_column vc_column_container vc_col-sm-2">
                    <div class="vc_column-inner">
                      <div class="wpb_wrapper"></div>
                    </div>
                  </div>
                  <div class="wpb_column vc_column_container vc_col-sm-8">
                    <div class="vc_column-inner vc_custom_1490279597723">
                      <div class="wpb_wrapper">
                        <div class="mgt-header-block clearfix text-center text-black wpb_content_element mgt-header-block-style-2 mgt-header-block-fontsize-medium mgt-header-texttransform-none mgt-header-block-50018052">
                          {/* <p class="mgt-header-block-subtitle">
                            {t("contactUs")}
                          </p> */}
                          <h2 class="mgt-header-block-title text-font-weight-default">
                            {/* What can we help you with */}
                            {/* Miben tudunk segíteni? */}
                            {t("contactUsPage.howCanWeHelp")}
                          </h2>
                          <div class="mgt-header-line mgt-header-line-margin-large"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="wpb_column vc_column_container vc_col-sm-2">
                    <div class="vc_column-inner">
                      <div class="wpb_wrapper"></div>
                    </div>
                  </div>
                </div>
                <div class="vc_row wpb_row vc_row-fluid vc_custom_1502030957960">
                  <div class="wpb_column vc_column_container vc_col-sm-6">
                    <div class="vc_column-inner vc_custom_1502031827735">
                      <div class="wpb_wrapper">
                        <div class="wpb_text_column wpb_content_element text-size-medium">
                          <div class="wpb_wrapper">
                            <h3 style={{ textAlign: "left" }}>
                              {/* Találjon meg minket! */}
                              {t("contactUsPage.findUs")}
                            </h3>
                            {/* <h3 style={{ textAlign: "left" }}>Find us</h3> */}
                            <p style={{ textAlign: "left" }}>
                              <span style={{ color: "#999999" }}>
                                3300 Eger
                              </span>
                              <br />
                              <span style={{ color: "#999999" }}>
                                Almár telep
                              </span>
                              <br />
                              <span style={{ color: "#999999" }}>
                                24313/4 HRSZ
                              </span>
                            </p>
                          </div>
                        </div>
                        <div class="mgt-button-wrapper mgt-button-wrapper-align-left mgt-button-wrapper-display-newline mgt-button-top-margin-true mgt-button-right-margin-false mgt-button-round-edges-full wpb_animate_when_almost_visible wpb_fadeInUp fadeInUp">
                          {/* <a
                            class="btn hvr-grow mgt-button-icon- mgt-button mgt-style-solid mgt-size-normal mgt-align-left mgt-display-newline mgt-text-size-normal mgt-button-icon-separator- mgt-button-icon-position-left text-font-weight-default mgt-text-transform-none"
                            href="#"
                          >
                            Find us on map
                          </a> */}
                          <div className="mapouter">
                            <div className="gmap_canvas">
                              <iframe
                                title="pipacsgooglemaps"
                                width="100%"
                                height="400px"
                                id="gmap_canvas"
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2671.951373401758!2d20.354913615642747!3d47.9566643792095!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47408f52725913ab%3A0x96511dd26ad2f1c4!2sPipacs%20Panzi%C3%B3%20Eger-Alm%C3%A1r!5e0!3m2!1shu!2shu!4v1649653572813!5m2!1shu!2shu"
                                frameBorder="0"
                                scrolling="no"
                                marginHeight="0"
                                marginWidth="0"
                              ></iframe>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="wpb_column vc_column_container vc_col-sm-6 vc_col-has-fill">
                    <div class="vc_column-inner vc_custom_1502031809115">
                      <div class="wpb_wrapper">
                        <div class="wpb_text_column wpb_content_element text-size-medium">
                          <div class="wpb_wrapper">
                            <h3 style={{ textAlign: "left" }}>
                              {/* Írjon nekünk üzenetet! */}
                              {t("contactUsPage.writeToUs")}
                            </h3>
                            <p style={{ textAlign: "left" }}>
                              <span style={{ color: "#999999" }}>
                                {/* Kérjük használja az alábbi formot az üzenet
                                küldésre */}
                                {t("contactUsPage.useContactForm")}
                              </span>
                              <br />
                              <span style={{ color: "#999999" }}>
                                {/* Amint tudunk válaszolunk az Ön üzenetére! */}
                                {t("contactUsPage.weWillReachYouOut")}
                              </span>
                            </p>
                          </div>
                        </div>
                        <div
                          role="form"
                          class="wpcf7"
                          id="wpcf7-f11-p1532-o1"
                          lang="en-US"
                          dir="ltr"
                        >
                          <div class="screen-reader-response"></div>
                          <Formik
                            initialValues={{
                              name: "",
                              email: "",
                              subject: "",
                              message: "",
                            }}
                            enableReinitialize
                            onSubmit={(values, { setSubmitting }) => {
                              displayToast(
                                "Üzenet elküldés átmenetileg NEM MŰKÖDIK! Kérjük keressen minket közvetlenül emailben vagy telefonon. Köszönjük!"
                              );
                              emailjs
                                .send(
                                  "service_qrg8zgr",
                                  "template_1ej1e4i",
                                  {
                                    name: "Pipacs",
                                    from_name: `${values.name}`,
                                    reply_to: values.email,
                                    subject: values.subject,
                                    message: values.message,
                                    site_name: "egerpipacs.hu",
                                    to_email: "info@egerpipacs.hu",
                                    from_email: "info@egerpipacs.hu",
                                  },
                                  "user_LrB06JmtNSOzWeUdvPyEj"
                                )
                                .then(() => {
                                  displayToast(
                                    "The send message option temporary doesn't work! Please, contact us via email or phone. Thank you!"
                                  );
                                  setSubmitting(false);
                                })
                                .catch((e) => {
                                  displayToast(
                                    "Sikertelen üzenetküldés! Kérjük próbálja meg később!"
                                  );
                                  setSubmitting(false);
                                });
                            }}
                            validationSchema={Yup.object().shape({
                              name: Yup.string().required(
                                "Név kitöltése kötelező!"
                              ),
                              email: Yup.string()
                                .required("Email kitöltése kötelező!")
                                .email("Hibás email cím!"),
                              message: Yup.string().required(
                                "Üzenet megadása kötelező!"
                              ),
                            })}
                          >
                            {({
                              handleBlur,
                              handleChange,
                              handleSubmit,
                              values,
                              isSubmitting,
                              errors,
                              touched,
                            }) => (
                              <form
                                class="quform"
                                noValidate
                                onSubmit={handleSubmit}
                              >
                                <div class="quform-elements">
                                  <div class="quform-element">
                                    <p>
                                      <span class="wpcf7-form-control-wrap your-name">
                                        <input
                                          id="name"
                                          type="text"
                                          name="name"
                                          size="40"
                                          class="input1"
                                          aria-required="true"
                                          aria-invalid="false"
                                          placeholder={t("contactUsPage.name")}
                                          onBlur={handleBlur}
                                          onChange={handleChange}
                                          value={values.name}
                                        ></input>
                                      </span>
                                    </p>
                                  </div>
                                  <div class="quform-element">
                                    <p>
                                      <span class="wpcf7-form-control-wrap your-email">
                                        <input
                                          id="email"
                                          type="email"
                                          name="email"
                                          size="40"
                                          class="input1"
                                          aria-required="true"
                                          aria-invalid="false"
                                          placeholder={t("contactUsPage.email")}
                                          onBlur={handleBlur}
                                          onChange={handleChange}
                                          value={values.email}
                                        ></input>
                                      </span>
                                    </p>
                                  </div>
                                  <div class="quform-element">
                                    <p>
                                      <span class="wpcf7-form-control-wrap your-message">
                                        <textarea
                                          id="message"
                                          name="message"
                                          cols="40"
                                          rows="10"
                                          class="input1"
                                          aria-invalid="false"
                                          placeholder={t(
                                            "contactUsPage.message"
                                          )}
                                          onBlur={handleBlur}
                                          onChange={handleChange}
                                          value={values.message}
                                        ></textarea>
                                      </span>
                                    </p>
                                  </div>
                                  <p>
                                    {/* <!-- Begin Submit button --> */}
                                    <div class="quform-submit">
                                      {isSubmitting ? (
                                        <div class="quform-loading-wrap">
                                          <span class="quform-loading"></span>
                                        </div>
                                      ) : (
                                        <div class="quform-submit-inner">
                                          <button
                                            type="submit"
                                            class="submit-button"
                                          >
                                            <span>
                                              {t("contactUsPage.send")}
                                            </span>
                                          </button>
                                        </div>
                                      )}
                                    </div>
                                  </p>
                                </div>
                              </form>
                            )}
                          </Formik>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="vc_separator wpb_content_element vc_separator_align_center vc_sep_width_100 vc_sep_pos_align_center vc_separator_no_text vc_sep_color_grey vc_custom_1502030988502 vc_custom_1502030988502">
                    <span class="vc_sep_holder vc_sep_holder_l">
                      <span class="vc_sep_line"></span>
                    </span>
                    <span class="vc_sep_holder vc_sep_holder_r">
                      <span class="vc_sep_line"></span>
                    </span>
                  </div>
                  <div class="wpb_text_column wpb_content_element contact-content">
                    <div class="wpb_wrapper">
                      <h3 style={{ textAlign: "center" }}>
                        {/* Étterem nyitvatartás */}
                        {t("contactUsPage.restaurantOpeningHours")}
                      </h3>
                      <p style={{ textAlign: "center" }}>
                        {/* Hétfő         Zárva */}
                        {t("contactUsPage.mondayOH")}
                        <br />
                        {/* Kedd          Zárva */}
                        {t("contactUsPage.tuesdayOH")}
                        <br />
                        {/* Szerda       Zárva */}
                        {t("contactUsPage.wednesday")}
                        <br />
                        {/* Csütörtök      9:00 &#8211; 20:00 */}
                        {t("contactUsPage.thursday")}
                        <br />
                        {/* Péntek            9:00 &#8211; 20:00 */}
                        {t("contactUsPage.fridayOH")}
                        <br />
                        {/* Szombat        9:00 &#8211; 20:00 */}
                        {t("contactUsPage.sundayOH")}
                        <br />
                        {/* Vasárnap        9:00 &#8211; 18:00 */}
                        {t("contactUsPage.saturdayOH")}
                        {/* Sunday           10:00 am &#8211; 11:00 pm */}
                      </p>
                      {t("contactUsPage.tableReservation")}{" "}
                      <a href="tel:+36-30-117-7949" class="__cf_email__">
                        +36 30 117 7949
                      </a>
                      <br />
                      E-mail:{" "}
                      <a href="mailto:sef@egerpipacs.hu " class="__cf_email__">
                        sef@egerpipacs.hu
                      </a>
                    </div>
                  </div>
                  <div class="vc_separator wpb_content_element vc_separator_align_center vc_sep_width_100 vc_sep_pos_align_center vc_separator_no_text vc_sep_color_grey">
                    <span class="vc_sep_holder vc_sep_holder_l">
                      <span class="vc_sep_line"></span>
                    </span>
                    <span class="vc_sep_holder vc_sep_holder_r">
                      <span class="vc_sep_line"></span>
                    </span>
                  </div>
                  <div class="wpb_text_column wpb_content_element contact-content">
                    <div class="wpb_wrapper">
                      <h3> {t("guesthouse")}</h3>
                      <p>
                        {t("footer.openEveryDay")} <br />
                        {t("contactUsPage.roomResarvation")}{" "}
                        <a href="tel:+36-30-984-0837" class="__cf_email__">
                          +36-30-984-0837
                        </a>
                        <br />
                        E-mail:{" "}
                        <a
                          href="mailto:info@egerpipacs.hu"
                          class="__cf_email__"
                        >
                          info@egerpipacs.hu
                        </a>
                        <br />
                        <a
                          href="mailto:foglalas@egerpipacs.hu"
                          class="__cf_email__"
                        >
                          foglalas@egerpipacs.hu
                        </a>
                      </p>
                    </div>
                  </div>
                  <div class="vc_separator wpb_content_element vc_separator_align_center vc_sep_width_100 vc_sep_pos_align_center vc_separator_no_text vc_sep_color_grey">
                    <span class="vc_sep_holder vc_sep_holder_l">
                      <span class="vc_sep_line"></span>
                    </span>
                    <span class="vc_sep_holder vc_sep_holder_r">
                      <span class="vc_sep_line"></span>
                    </span>
                  </div>
                  <div class="wpb_text_column wpb_content_element contact-content">
                    <div class="wpb_wrapper">
                      <h3>
                        {/* Közösségi média */}
                        {t("socialMedia")}
                      </h3>
                      <p>
                        <a
                          href="https://www.facebook.com/pipacsetteremeger/"
                          class="__cf_email__"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <i class="fa fa-facebook"></i>
                        </a>
                      </p>
                      <p>
                        <a
                          href="https://www.instagram.com/pipacsetteremespanzio/"
                          class="__cf_email__"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <i class="fa fa-instagram"></i>
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  class="vc_row wpb_row vc_row-fluid vc_custom_1502044403439"
                  // style={{ display: "flex", justifyContent: "center" }}
                >
                  <div class="wpb_column vc_column_container vc_col-sm-12 vc_col-md-4">
                    <div class="vc_column-inner">
                      <div class="wpb_wrapper">
                        <div class="mgt-promo-block-container wpb_content_element">
                          <div class="mgt-promo-block-wrapper mgt-promo-block-hover">
                            <div
                              class="mgt-promo-block animated white-text cover-image darken mgt-promo-block-66330768"
                              data-style="background-color: #f5f5f5;background-image: url(assets/img/about_us_pipacs_food7.webp);background-repeat: no-repeat;height: 300px;"
                            >
                              <div class="mgt-promo-block-content va-middle">
                                <div class="mgt-promo-block-content-inside">
                                  <h5 style={{ textAlign: "center" }}>
                                    {/* Mennyei ízek */}
                                    {t("goodTastes")}
                                  </h5>
                                  <h2 style={{ textAlign: "center" }}>
                                    {/* Tekintse meg  */}
                                    {t("lookAt")}
                                    <br />
                                    {/* étlapunkat! */}
                                    {t("ourMenu")}
                                  </h2>
                                  <div class="mgt-button-wrapper mgt-button-wrapper-align-center mgt-button-wrapper-display-newline mgt-button-top-margin-true mgt-button-right-margin-false mgt-button-round-edges-full">
                                    <a
                                      class="btn hvr-grow mgt-button-icon- mgt-button mgt-style-solid mgt-size-large mgt-align-center mgt-display-newline mgt-text-size-small mgt-button-icon-separator- mgt-button-icon-position-left text-font-weight-default mgt-text-transform-none"
                                      href="menu"
                                    >
                                      {/* Étlap */}
                                      {t("menu")}
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="wpb_column vc_column_container vc_col-sm-12 vc_col-md-4">
                    <div class="vc_column-inner">
                      <div class="wpb_wrapper">
                        <div class="mgt-promo-block-container wpb_content_element">
                          <div class="mgt-promo-block-wrapper mgt-promo-block-hover">
                            <div
                              class="mgt-promo-block animated white-text cover-image darken mgt-promo-block-75835486"
                              data-style="background-color: #f5f5f5;background-image: url(assets/img/about_us_pipacs_bed2.webp);background-repeat: no-repeat;height: 300px;"
                            >
                              <div class="mgt-promo-block-content va-middle">
                                <div class="mgt-promo-block-content-inside">
                                  <h5 style={{ textAlign: "center" }}>
                                    {/* Teljes kikapcsolódás */}
                                    {t("totalRelax")}
                                  </h5>
                                  <h2 style={{ textAlign: "center" }}>
                                    {/* Tekintse meg */}
                                    {t("lookAt")}
                                    <br />
                                    {/* szobáinkat! */}
                                    {t("ourRooms")}
                                  </h2>
                                  <div class="mgt-button-wrapper mgt-button-wrapper-align-center mgt-button-wrapper-display-newline mgt-button-top-margin-true mgt-button-right-margin-false mgt-button-round-edges-full">
                                    <a
                                      class="btn hvr-grow mgt-button-icon- mgt-button mgt-style-solid mgt-size-large mgt-align-center mgt-display-newline mgt-text-size-small mgt-button-icon-separator- mgt-button-icon-position-left text-font-weight-default mgt-text-transform-none"
                                      href="rooms"
                                    >
                                      {/* Szobák */}
                                      {t("rooms")}
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="wpb_column vc_column_container vc_col-sm-12 vc_col-md-4">
                    <div class="vc_column-inner">
                      <div class="wpb_wrapper">
                        <div class="mgt-promo-block-container wpb_content_element">
                          <div class="mgt-promo-block-wrapper mgt-promo-block-hover">
                            <div
                              class="mgt-promo-block animated white-text cover-image darken mgt-promo-block-75835486"
                              data-style="background-color: #f5f5f5;background-image: url(assets/img/pipacs_wedding_1.webp);background-repeat: no-repeat;height: 300px;"
                            >
                              <div class="mgt-promo-block-content va-middle">
                                <div class="mgt-promo-block-content-inside">
                                  <h5 style={{ textAlign: "center" }}>
                                    {/* Teljes kikapcsolódás */}
                                    {t("unforgettableExperience")}
                                  </h5>
                                  <h2 style={{ textAlign: "center" }}>
                                    {/* Tekintse meg */}
                                    {t("lookAt")}
                                    <br />
                                    {/* szobáinkat! */}
                                    {t("ourEventRoom")}
                                  </h2>
                                  <div class="mgt-button-wrapper mgt-button-wrapper-align-center mgt-button-wrapper-display-newline mgt-button-top-margin-true mgt-button-right-margin-false mgt-button-round-edges-full">
                                    <a
                                      class="btn hvr-grow mgt-button-icon- mgt-button mgt-style-solid mgt-size-large mgt-align-center mgt-display-newline mgt-text-size-small mgt-button-icon-separator- mgt-button-icon-position-left text-font-weight-default mgt-text-transform-none"
                                      href="rooms"
                                    >
                                      {/* Szobák */}
                                      {t("eventHall")}
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </article>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default ContactUs;
